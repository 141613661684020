<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">


                <div class="admin_main_block_left">
                    <div><router-link to="/Admin/duansplb/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                    <div><el-input v-model="where.F_VR_NIC" placeholder="用户昵称"></el-input></div>
                    <div style="width:160px;">
                        <el-select v-model="where.F_TI_STATUS" placeholder="选择类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="待审核" value="1"></el-option>
                            <el-option label="已通过" value="2"></el-option>
                            <el-option label="已拒绝" value="3"></el-option>
                        </el-select>
                    </div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>


                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="40px"></el-table-column>
                    <el-table-column prop="F_VR_OPENID" label="用户openid"></el-table-column>
                    <el-table-column prop="F_VR_NIC" label="用户昵称"></el-table-column>

                    <el-table-column label="微信头像">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_IN_DUANSPYQ_ID" label="短视频营期"></el-table-column>

                    <el-table-column prop="F_VR_SHIPBT" label="视频标题"></el-table-column>
                    <el-table-column prop="F_VR_FENGXBT" label="分享标题"></el-table-column>
                    <el-table-column prop="F_VR_JINGMGG" label="静默广告"></el-table-column>

					
					<el-table-column label="封面">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_FENGMT||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>

                    <el-table-column label="视频" width="140px">
                        <template slot-scope="scope">
                        <video-player
                            ref="videoPlayer" :playsinline="true"  :options="playerOptionss[scope.$index]">
                        </video-player>
                        </template>
                    </el-table-column>



                    <el-table-column prop="F_IN_CHUANGJSJ" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_VR_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_VR_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="F_IN_DIANZS" label="点赞数"></el-table-column>
                    <el-table-column prop="F_IN_FENXS" label="分享数"></el-table-column>


                    <el-table-column label="审核状态">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_STATUS==2">审核通过</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_STATUS==3">审核拒绝</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_STATUS==1">待审核</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <!--<el-button icon="el-icon-edit" :disabled="scope.row.F_TI_STATUS==2 || scope.row.F_TI_STATUS==3" @click="verify_click(scope.row.F_IN_ID)">审核</el-button>-->
                            <el-button icon="el-icon-edit"  @click="verify_click(scope.row.F_IN_ID)">审核</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                    <!--<el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'adv_form',params:{id:scope.row.id}})">编辑</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css'
export default {
    name: "TestTwo",
    components: {videoPlayer},
    computed:{
        player(){
            return this.$refs.videoPlayer.player
        }
    },
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
              F_VR_IDENTIFY:'',
          },
          playerOptions:{
              //播放速度
              playbackRates:[0.5,1.0,1.5,2.0],
              //如果true,浏览器准备好时开始回放.
              autoplay:false,
              //默认情况下将会消除任何音频
              muted:false,
              //导致视频一结束就重新开始
              loop:false,
              //建议浏览器在<video>加载元素后是否应该开始下载视频
              preload:'auto',
              language:'zh-CN',
              aspectRatio:'16:9',
              fluid:true,
              sources:[{
                  //类型
                  type:"video/mp4",
                  //url地址
                 src:'http://wechat.cloudliveshop.com/Uploads/upload/1610248596.mp4'
              }],
              poster:'',
              //允许覆盖Video.js无法播放媒体源时显示的默认信息
              notSupportedMessage:'此视频暂无法播放，请稍后再试',
              controlBar:{
                  timeDivider:true,
                  durationDisplay:true,
                  remainingTimeDisplay:false,
                  //全屏按钮
                  fullscreenToggler:true
              }
          },
          playerOptionss:[]
      };
    },
    watch: {},

    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getDuansplb,this.where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.current_page = res.data.current_page;
                this.list = res.data.data;

                // 遍历数组B，以id的值作为数组的key，name的值作为数组的value,存放到临时数组arrayC
                for(let i = 0; i < res.data.data.length; i ++) {
                    // 为arrayC添加名称为arrayB[i].id的属性字段，值等于arrayB[i].name
                    console.log('取值')
                    console.log(res.data.data[i].F_VR_HECHSP)
                    console.log('取值')

                    let arrStr = {
                        //播放速度
                        playbackRates:[0.5,1.0,1.5,2.0],
                        //如果true,浏览器准备好时开始回放.
                        autoplay:false,
                        //默认情况下将会消除任何音频
                        muted:false,
                        //导致视频一结束就重新开始
                        loop:false,
                        //建议浏览器在<video>加载元素后是否应该开始下载视频
                        preload:'auto',
                        language:'zh-CN',
                        aspectRatio:'16:9',
                        fluid:true,
                        sources:[{
                            //类型
                            type:"video/mp4",
                            //url地址
                            src:res.data.data[i].F_VR_HECHSP
                        }],
                        poster:'',
                        //允许覆盖Video.js无法播放媒体源时显示的默认信息
                        notSupportedMessage:'此视频暂无法播放，请稍后再试',
                        controlBar:{
                            timeDivider:true,
                            durationDisplay:true,
                            remainingTimeDisplay:false,
                            //全屏按钮
                            fullscreenToggler:true
                        }
                    }

                    this.playerOptionss.push(arrStr)

                }



            })

            //this.playerOptions['sources'][0]['src'] = 'http://wechat.cloudliveshop.com/Uploads/upload/1610248596.mp4';
            //this.$refs.video.src = 'http://wechat.cloudliveshop.com/Uploads/upload/1610248596.mp4';
        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delDuansplb,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
        verify_click:function(F_IN_ID){
            this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                confirmButtonText: '同意',
                cancelButtonText: '拒绝',
                type: 'info'
            }).then(() => {
                this.$post(this.$api.duansplbChange,{F_IN_ID:F_IN_ID,F_TI_STATUS:2}).then(()=>{
                    this.$message.success('成功审核');
                    this.get_adv_list();
                });

            }).catch(() => {
                this.$post(this.$api.duansplbChange,{F_IN_ID:F_IN_ID,F_TI_STATUS:3}).then(()=>{
                    this.$message.info('已拒绝');
                    this.get_adv_list();
                });

            });
        },
    },
    created() {

        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>